import React, { useEffect, useState } from 'react';
import * as s from './styles';
import { setPropsFromURL } from '../../../util/cta';
import iconArrowRight from '../../../assets/icons/icon-arrow-right.svg';
import iconCopy from '../../../assets/icons/icon-clipboard.png';
import iconCopyRollover from '../../../assets/icons/icon-clipboard-rollover.png';
import iconCopied from '../../../assets/icons/icon-copied.svg' ;

const Button = ({
  type = 'primary',
  icon = 'none',
  shadow = 'none',
  border = 'primary',
  underline = false,
  to,
  newWindow = true,
  className = '',
  children,
  onClick,
  copyContent = '',
  ...props
}) => {
  const linkProps = (to) ? setPropsFromURL(to, newWindow) : {as: 'button'};
  const [buttonLabel, setButtonLabel] = useState(children);
  const [copied, setCopied] = useState(false);
  const [classNameTxt, setClassNameTxt] = useState(false);
  const [copyhover, setCopyHover] = useState(false);
  const [copyhoverhide, setCopyHoverhide] = useState(false);


  useEffect(() => {
    if (copied) {
      setTimeout(() => setCopied(false), 5000)
      setTimeout(() => setButtonLabel('Copy and paste questions'), 5000)
      setTimeout(() => setClassNameTxt(false), 5000)
      setTimeout(() => setCopyHoverhide(false), 5000)
    }
  },[copied])

  return (
    <s.Button
      type = {type}
      icon = {icon}
      shadow = {shadow}
      border = {border}
      underline = {underline}
      {...linkProps}
      className = {[className, classNameTxt]}
      copied = {copied}
      activeClassName="current" 
      onClick={
        (e) => {
          if(typeof onClick === 'function'){
            onClick(e);
          }
          if(icon === 'copy'){
            navigator.clipboard.writeText(copyContent);
            setButtonLabel('Copied to clipboard!');
            setCopied(true);
            setClassNameTxt('textCopied');
            setCopyHoverhide(true);
          } 
        }
      }
      onMouseOver={
        (e) => {
          if(icon === 'copy'){
            setCopyHover(true);
          }
        }
      }
      onMouseOut={
        (e) => {
          if(icon === 'copy'){
            setCopyHover(false);
          }
        }
      }
      {...props}
    >
      {icon === 'arrow-left-inverse' && <img src={iconArrowRight} alt='' className='arrow-left-inverse' aria-hidden/>}
      {icon === 'arrow-left' && <img src={iconArrowRight} alt='' className='arrow-left' aria-hidden/>}
      {buttonLabel}
      {icon === 'arrow-right' && <img src={iconArrowRight} alt='' className='arrow-right' aria-hidden/>}
      {icon === 'copy' && <img src={copied ? iconCopied : iconCopy} alt='Text copied' className='copy'  aria-hidden/>}
      {icon === 'copy' && <img src={copyhover ? iconCopyRollover : iconCopy} alt='Click to copy text' className={copyhoverhide ? 'copy hide' : 'copy'}  aria-hidden/>}
    </s.Button>
  );
};


export default Button;