import nsan from '../../assets/fonts/NotoSans-Regular.ttf';
import nsani from '../../assets/fonts/NotoSans-Italic.ttf';

import nsanl from '../../assets/fonts/NotoSans-Light.ttf';
import nsanli from '../../assets/fonts/NotoSans-LightItalic.ttf';

import nsanel from '../../assets/fonts/NotoSans-ExtraLight.ttf';
import nsaneli from '../../assets/fonts/NotoSans-ExtraLightItalic.ttf';

import nsanm from '../../assets/fonts/NotoSans-Medium.ttf';
import nsanmi from '../../assets/fonts/NotoSans-MediumItalic.ttf';

import nsanbo from '../../assets/fonts/NotoSans-Bold.ttf';
import nsanboi from '../../assets/fonts/NotoSans-BoldItalic.ttf';

import nsaneb from '../../assets/fonts/NotoSans-ExtraBold.ttf';
import nsanebi from '../../assets/fonts/NotoSans-ExtraBoldItalic.ttf';

import nsanb from '../../assets/fonts/NotoSans-Black.ttf';
import nsanbi from '../../assets/fonts/NotoSans-BlackItalic.ttf';

import nsant from '../../assets/fonts/NotoSans-Thin.ttf';
import nsanti from '../../assets/fonts/NotoSans-ThinItalic.ttf';

import nsansb from '../../assets/fonts/NotoSans-SemiBold.ttf';
import nsansbi from '../../assets/fonts/NotoSans-SemiBoldItalic.ttf';

import nser from '../../assets/fonts/NotoSerif-Regular.ttf';
import nseri from '../../assets/fonts/NotoSerif-Italic.ttf';

import nserb from '../../assets/fonts/NotoSerif-Bold.ttf';
import nserbi from '../../assets/fonts/NotoSerif-BoldItalic.ttf';

const fonts = `
/* Regular */
@font-face {
    font-family: 'noto-sans';
    src: url('${nsan}') format('truetype');
    font-style: normal;
    font-weight: 400;
  }
  
/* Regular Italic */
@font-face {
    font-family: 'noto-sans';
    src: url('${nsani}') format('truetype');
    font-style: italic;
    font-weight: 400;
}

/* Light */
@font-face {
    font-family: 'noto-sans';
    src: url('${nsanl}') format('truetype');
    font-style: normal;
    font-weight: 300;
}

/* Light Italic */
@font-face {
    font-family: 'noto-sans';
    src: url('${nsanli}') format('truetype');
    font-style: italic;
    font-weight: 300;
}

/* Extra Light */
@font-face {
    font-family: 'noto-sans';
    src: url('${nsanel}') format('truetype');
    font-style: normal;
    font-weight: 200;
}

/* Extra Light Italic */
@font-face {
    font-family: 'noto-sans';
    src: url('${nsaneli}') format('truetype');
    font-style: italic;
    font-weight: 200;
}

/* Medium */
@font-face {
    font-family: 'noto-sans';
    src: url('${nsanm}') format('truetype');
    font-style: normal;
    font-weight: 500;
}

/* Medium Italic*/
@font-face {
    font-family: 'noto-sans';
    src: url('${nsanmi}') format('truetype');
    font-style: italic;
    font-weight: 500;
}

/* Bold */
@font-face {
    font-family: 'noto-sans';
    src: url('${nsanbo}') format('truetype');
    font-style: normal;
    font-weight: 700;
}

/* Bold Italic*/
@font-face {
    font-family: 'noto-sans';
    src: url('${nsanboi}') format('truetype');
    font-style: italic;
    font-weight: 700;
}

/* Extra Bold */
@font-face {
    font-family: 'noto-sans';
    src: url('${nsaneb}') format('truetype');
    font-style: normal;
    font-weight: 800;
}

/* Extra Bold Italic */
@font-face {
    font-family: 'noto-sans';
    src: url('${nsanebi}') format('truetype');
    font-style: italic;
    font-weight: 800;
}

/* Black */
@font-face {
    font-family: 'noto-sans';
    src: url('${nsanb}') format('truetype');
    font-style: normal;
    font-weight: 900;
}

/* Black Italic */
@font-face {
    font-family: 'noto-sans';
    src: url('${nsanbi}') format('truetype');
    font-style: italic;
    font-weight: 900;
}

/* Thin */
@font-face {
    font-family: 'noto-sans';
    src: url('${nsant}') format('truetype');
    font-style: normal;
    font-weight: 100;
}

/* Thin Italic */
@font-face {
    font-family: 'noto-sans';
    src: url('${nsanti}') format('truetype');
    font-style: italic;
    font-weight: 100;
}

/* Semi-bold */
@font-face {
    font-family: 'noto-sans';
    src: url('${nsansb}') format('truetype');
    font-style: normal;
    font-weight: 600;
}

/* Semi-bold Italic */
@font-face {
    font-family: 'noto-sans';
    src: url('${nsansbi}') format('truetype');
    font-style: italic;
    font-weight: 600;
}

/* Regular */
@font-face {
    font-family: 'noto-serif';
    src: url('${nser}') format('truetype');
    font-style: normal;
    font-weight: 400;
}

/* Regular Italic */
@font-face {
    font-family: 'noto-serif';
    src: url('${nseri}') format('truetype');
    font-style: italic;
    font-weight: 400;
}

/* Bold */
@font-face {
    font-family: 'noto-serif';
    src: url('${nserb}') format('truetype');
    font-style: normal;
    font-weight: 700;
}

/* Bold Italic*/
@font-face {
    font-family: 'noto-serif';
    src: url('${nserbi}') format('truetype');
    font-style: italic;
    font-weight: 700;
}

  `;

export default fonts;