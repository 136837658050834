import React from 'react';

import Header from '../../elements/Header';
import Footer from '../../elements/Footer';

// styles
import GlobalStyles from '../../../styles/global';
import * as s from './styles';


const PageLayout = ({children}) => {
  return (
    
    <s.StyledLayout>
      <noscript>
      {`
        <iframe src=https://www.googletagmanager.com/ns.html?id=GTM-PPTFBL7
        height="0" width="0" style="display:none;visibility:hidden"></iframe>
        `
      }
      </noscript>

      <GlobalStyles />

      <Header />

      <s.PageMain>
        {children}
      </s.PageMain>

      <Footer />

    </s.StyledLayout>
  );
}


export default PageLayout;