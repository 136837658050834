import React, { useState } from 'react';
import * as s from './styles';
import headerCopy from '../../../copy/header';
import talkToDoctorCopy from '../../../copy/talkToDoctorShort';

const Header = () => {
  const [navOpen, setNavOpen] = useState(false);

  const onOverlayClick = (e) => {
    e.stopPropagation();
    setNavOpen(false);
    document.body.classList.remove('no-scroll');
  };

  return (
    <s.WeliregHeader>
      <s.HeaderWrapper>

        <s.LogoLink to="/" data-design-category="top_nav_vd">
          <img src={headerCopy.logo} alt={headerCopy.logoAlt} />
        </s.LogoLink>
 
        <s.NavItems>
          <s.NavLinks>
            {headerCopy.navItems.map((navItem, i) => (
              <s.NavItem
                key={i} 
                to={navItem.url}
                data-design-category="top_nav_vd"
                aria-label={navItem.label}
              >
                {navItem.label}
              </s.NavItem>
            ))}
          </s.NavLinks>
        </s.NavItems>

        <s.HamburgerButton
            onClick={() => {
              setNavOpen(!navOpen);
              document.body.classList.toggle('no-scroll');
            }}
            className={navOpen ? 'open' : ''}
            data-design-category="button_vd"
            aria-label={navOpen ? 'Close menu' : 'Open menu'}
          >
        </s.HamburgerButton>

        <s.MobileNavOverlay
            isActive={navOpen}
            onClick={onOverlayClick}
          />

          <s.NavTray className={navOpen ? 'open' : 'hidden'}>

            <s.MobileHeaderWrap>
              <s.MobileLogoLink
                to="/"
                onClick={onOverlayClick}
                data-design-category="top_nav_vd"
              >
                <img src={headerCopy.logo} alt={headerCopy.logoAlt} />
              </s.MobileLogoLink>

              <s.HamburgerButton
                  onClick={() => {
                    setNavOpen(!navOpen);
                    document.body.classList.toggle('no-scroll');
                  }}
                  className={navOpen ? 'open' : ''}
                  data-design-category="button_vd"
                  aria-label={navOpen ? 'Close menu' : 'Open menu'}
                >
              </s.HamburgerButton>
            </s.MobileHeaderWrap>

            <s.PrimaryNav>
              {
                headerCopy.navItems.map((navItem, i) => (
                  <s.NavItem
                    key={i}
                    border='none'
                    to={ navItem.url }
                    onClick={onOverlayClick}
                    className='mobile-menu'
                    icon='arrow-right'
                    data-design-category="top_nav_vd"
                  >
                    { navItem.label }
                  </s.NavItem>
                ))
              }
            </s.PrimaryNav>
            <s.MobileDocQuestions>
              <s.MobileDocQuestionTitle>{talkToDoctorCopy.title}</s.MobileDocQuestionTitle>
              <s.MobileDocQuestionCopy>{talkToDoctorCopy.copy}</s.MobileDocQuestionCopy>
              <s.DocQuestionsButton icon='arrow-right' type='primary-inverse' border='white' to={talkToDoctorCopy.buttonTo} onClick={onOverlayClick}>{talkToDoctorCopy.buttonLabel}</s.DocQuestionsButton>
              
            </s.MobileDocQuestions>
            <s.HcpButton className='mobile-menu' border='white' to={headerCopy.hcpButtonTo} data-design-category="top_nav_vd">{headerCopy.hcpButtonLabel}</s.HcpButton>
            <s.MobileNavLinks>
              {
                  headerCopy.mobileNavLinks.map((link, i) => (
                    <s.MobileNavLink
                      key={i}
                      to={link.url}
                      type={link.type}
                      href={link.href}
                      className={link.className}
                      underline
                      data-design-category={link.dataDesignCategory}
                    >
                      {link.label}
                    </s.MobileNavLink>
                  ))
                }
            </s.MobileNavLinks>
          </s.NavTray>
      </s.HeaderWrapper>
    </s.WeliregHeader>
  )
}


export default Header;