import logoAccessibility from '../assets/images/logo-accessibility.svg';
import logoMerck from '../assets/images/logo-merck.svg';
import privacyChoicesImage from '../assets/images/yourprivacychoices.svg';



const footerCopy = {
  sitemapLinks: [
    {
      label: 'What is VHL disease?',
      href: '/what-is-vhl/',
    },
    {
      label: 'VHL and genetics',
      href: '/vhl-and-genetics/',
    },
    {
      label: 'How is VHL diagnosed?',
      href: '/how-is-vhl-diagnosed/',
    },
  ],

  legalLinks: [
    {
      label: 'Privacy policy',
      url: 'https://www.msdprivacy.com/us/en/index.html',
      type: 'tertiary',
      border: 'none',
      dataDesignCategory: 'bottom_nav_vd',
      className:'footer__horizontal-list-link',
      href:'https://www.msdprivacy.com/us/en/index.html'
    },
    {
      label: 'Terms of use',
      url: 'https://www.merck.com/policy/terms-of-use/home.html',
      type: 'tertiary',
      border: 'none',
      dataDesignCategory: 'bottom_nav_vd',
      className:'footer__horizontal-list-link',
      href:'https://www.merck.com/policy/terms-of-use/home.html'
    },
    {
      label: 'Cookie Preferences',
      type:'tertiary',
      href:"javascript:void(0)",
      className:"styles__Button-dwPtCw kANAfb styles__LegalLink-cunORN lmEMUp footer__horizontal-list-link ot-sdk-show-settings",
      dataDesignCategory:'bottom_nav_vd',
    },
    {
      label: 'Accessibility',
      url: 'https://www.msdaccessibility.com/',
      type: 'tertiary',
      border: 'none',
      dataDesignCategory: 'bottom_nav_vd',
      className:'footer__horizontal-list-link',
      href:'https://www.msdaccessibility.com/'
    },
    {
      label: 'Your Privacy Choices',
      url: 'https://dsr.msd.com/DSR/United_States/English/',
      type: 'tertiary',
      border: 'none',
      dataDesignCategory: 'bottom_nav_vd',
      className:'footer__horizontal-list-link',
      href:'https://dsr.msd.com/DSR/United_States/English/',
      image: privacyChoicesImage
    },
    {
      label: 'Consumer Health Data Privacy Policy',
      url: 'https://www.msdprivacy.com/us/en/chd-policy/',
      type: 'tertiary',
      border: 'none',
      dataDesignCategory: 'bottom_nav_vd',
      className:'footer__horizontal-list-link',
      href:'https://www.msdprivacy.com/us/en/chd-policy/'
    }

  ],

  copyright: [
    'Copyright &copy; 2023 Merck &amp; Co., Inc., Rahway, NJ, USA and its affiliates. All rights reserved.',
    'This site is intended only for residents of the United States, its territories, and Puerto Rico.',
    'US-BEL-00871 08/23',
  ],

  legalLinksAfterCookie: [
    {
      label: 'Accessibility',
      url: 'https://www.msdaccessibility.com'
    },
  ],

  privacyImgLinks: [
    {
      img: logoAccessibility,
      alt: 'Level Access Company Logo',
      url: 'https://www.essentialaccessibility.com/merck/?utm_source=merck-homepage&utm_medium=icon-large&utm_term=eachannel-page&utm_content=header&utm_campaign=merck',
    },
  ],

  merckLogo: logoMerck,
};


export default footerCopy;
