import styled from 'styled-components';
import { respondTo } from '../../../styles/shared/breakpoints';
import { greyCopy, greyLighter, greyLightest, linkBlue } from '../../../styles/shared/colors';
import Button from '../Button';
import { WrapperEl } from '../../../styles/shared/layout';


export const WeliregFooter = styled.footer`
  background-color: ${greyLightest};
`;

export const FooterWrapper = styled(WrapperEl)`
  padding: 25px 30px 32px 30px;
  ${respondTo.sm`
    padding: 36px 60px 64px 60px;
  `}
`;

export const FooterTop = styled.div`
  padding: 0  0 32px 0;
  display: flex;
  flex-direction: column;
  align-items: baseline;
  position: relative;
  div {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    padding: 0;
  }
  ${respondTo.sm`
    flex-direction: row;
    div {
    flex-direction: row;
    }
    justify-content: space-between;
  `}
  &::after {
    content: '';
    position: absolute;
    left: -12px;
    bottom: 0;
    height: 1px;
    width: calc(100% + 24px);
    background-color: ${greyLighter};
  }
`;


export const FooterBottom = styled.div`
  padding: 24px 0 0 0;
  ${respondTo.sm`
      padding: 31px 0 0 0;
  `}
`;


export const siteLink = styled(Button)`
  padding-top: 0;
  padding-bottom: 0;
  font-size: 14px;
  line-height: 20px;
  color: ${linkBlue};
  padding-left: 18px;
  .arrow-left-inverse{
    filter: invert(29%) sepia(65%) saturate(4264%) hue-rotate(194deg) brightness(89%) contrast(76%);
    width: 9px;
    height: 9px;
  }
  margin: 0 0 20px 0;
  &:last-child {
    margin: 0 0 32px 0;
    ${respondTo.sm`
      margin: 0 35px 0 0;
    `}
  }
  ${respondTo.sm`
    margin: 0 35px 0 0;
  `}
  &:hover {
    text-decoration: none;
  }
`;


export const LegalLinkRow = styled.div`
  margin-bottom: 14px;
  list-style: none;
  display: flex;
  position: relative;
  flex-wrap: wrap;
  justify-content: center;

  ${respondTo.sm`
      margin-bottom: 29px;
      justify-content: left;
  `}
  li.footer__horizontal-list-item {
    
    margin: 0 5px;
    padding-right: 5vw;
    position: relative;
    text-align: left;

    &:after {
      position: absolute;
      left: -9px;
    }

    &:first-child {
      margin-left: 0;
    }

    &:nth-child(1) {
      a:after {
        display: none;
      }
    }
    ${respondTo.sm`
        flex: none;
        margin: 0 16px;
        padding-right: 0px;
    `}
  }
  .footer__horizontal-list-link:link, 
  .footer__horizontal-list-link[href] {
      color: ${linkBlue};
      pointer-events: all;
      &:hover {
        text-decoration: none;
      }
  }
  .ot-sdk-show-settings {
    cursor: pointer;
    color: ${linkBlue};
    font-size: 12px;
    line-height: 30px;
    font-weight: 700;
    position: relative;
    text-decoration: underline;
    pointer-events: all;
    border: none;
    background: transparent;
    padding: 0;
    white-space: nowrap;

    &:after {
      content: '';
      position: absolute;
      left: -9px;
    }

    ${respondTo.sm`
      font-size: 14px;
      line-height: 20px;
      &:after {
        content: '•';
        position: absolute;
        left: -18px;
      }
      &:before {
        content: "";
        white-space: pre;
      }
    `}
    
  }
`;

export const HcpButton = styled(Button)`
  padding: 6px 17px 8px 14px;
  margin: 0 auto;
  font-size: 13px;
  line-height: 20px;
  ${respondTo.sm`
    margin: 0;
    &:hover {
      background: ${linkBlue};
      color: #ffffff;
    }
  `}
`;

export const LegalLink = styled(Button)`
  margin: 0 16px;
  color: ${linkBlue};
  font-size: 12px;
  line-height: 30px;
  padding: 0;
  white-space: nowrap;

  &:after {
    content: '•';
    position: absolute;
    left: -4vw;
  }

  ${respondTo.sm`
    font-size: 14px;
    line-height: 20px;
    &:after {
      left: -16px;
      transform: translateX(-50%);
    }
  `}

 

  &:first-child {
    margin-left: 0;
    &:before {
      display: none;
    }
  }
  &:last-child {
    margin-right: 0;
  }
`;


export const CopyrightRow = styled.div`
  margin-bottom: 25px;
  ${respondTo.sm`
    margin-bottom: 21px;
  `}
  p {
    margin: 0;
    font-size: 14px;
    line-height: 22px;
    color: ${greyCopy};

    &:last-child {
      margin-top: 12px;
      ${respondTo.sm`
        margin-top: 2px;
      `}
    }
  }
`;

export const LogoRow = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
  gap: 32px;
`;

export const PrivacyLinks = styled.div`
  flex: 1 0 0;
  text-align: left;
  display: flex;

  a {
    display: inline-block;
    margin-left: 12px;
    ${respondTo.sm`
      margin-left: 25px;
    `}
    img {
      display: block;
      height: 35px;
      ${respondTo.sm`
        height: auto;
      `}
    }

    &:nth-child(3){
      img {
        height: 25px;
        ${respondTo.sm`
          height: auto;
        `}
      }
    }

    &:first-child {
      margin: 0;
    }
  }
`;

export const MerckLogo = styled.img`
  display: inline-block;
  flex: 0 0 auto;
  height: 35px;
  width: auto;
`;

export const CookieButton = styled(Button)`
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 0;
`;