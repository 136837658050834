import React from 'react';

import * as s from './styles';
import footerCopy from '../../../copy/footer';

const Footer = () => {
  return (
    <s.WeliregFooter>
        <s.FooterWrapper>
          <s.FooterTop>
            <div>
            {footerCopy.sitemapLinks.map((siteLink, i) => (
              <s.siteLink
                key={i}
                to={siteLink.href}
                underline
                type='tertiary'
                icon='arrow-left-inverse'
                border='none'
                data-design-category='bottom_nav_vd'
              >
                {siteLink.label}
              </s.siteLink>
            ))}
            </div>
          </s.FooterTop>

          <s.FooterBottom>

            <s.LegalLinkRow>
              {footerCopy.legalLinks.map((legalLink, i) => (
                <li className='footer__horizontal-list-item'>
                  <s.LegalLink
                    type={legalLink.type}
                    border={legalLink.border}
                    underline
                    key={i}
                    to={legalLink.url}
                    id={legalLink.id}
                    class={legalLink.class}
                    data-design-category={legalLink.dataDesignCategory}
                    className={legalLink.className}
                    href={legalLink.href}
                  >
                  {legalLink.label}
                    {/* Conditionally render the image if it exists */}
                    {legalLink.image && (
                      <img
                        src={legalLink.image}
                        alt={``}
                        style={{ marginLeft: '8px', verticalAlign: 'middle', width: '30px', height: '14px' }} // Adjust styles as needed
                      />
                    )}
                </s.LegalLink></li>
              ))}
                
            </s.LegalLinkRow>

            <s.CopyrightRow>
              {footerCopy.copyright.map((copyLine, i) => (
                <p
                  key={i}
                  dangerouslySetInnerHTML={{__html: copyLine}}
                />
              ))}
            </s.CopyrightRow>

            <s.LogoRow>
              <s.PrivacyLinks>
                {footerCopy.privacyImgLinks.map((privacyLink, i) => (
                  <a
                    key={i}
                    href={privacyLink.url}
                    data-design-category='bottom_nav_vd'
                  >
                    <img src={privacyLink.img} alt={privacyLink.alt} />
                  </a>
                ))}
              </s.PrivacyLinks>

              <s.MerckLogo src={footerCopy.merckLogo} alt="Merck Logo"/>
            </s.LogoRow>

          </s.FooterBottom>
        </s.FooterWrapper>
    </s.WeliregFooter>
  );
}


export default Footer;
