import logo from '../assets/images/Could_it_be_VHL_Logo.svg';
const headerCopy = {
  logo: logo,
  logoAlt: 'Could it be VHL Homepage',
  navItems: [
    {
      label: 'What is VHL disease?',
      url: '/what-is-vhl/',
    },
    {
      label: 'VHL and genetics',
      url: '/vhl-and-genetics/',
    },
    {
      label: 'How is VHL diagnosed?',
      url: '/how-is-vhl-diagnosed/',
    },
  ],
  mobileNavLinks: [
    {
      label: 'Privacy policy',
      url: 'https://www.msdprivacy.com/us/en/index.html',
      dataDesignCategory:'top_nav_vd',
    },
    {
      label: 'Terms of use',
      url: 'https://www.merck.com/policy/terms-of-use/home.html',
      dataDesignCategory:'top_nav_vd',
    },
    {
      label: 'Cookie Preferences',
      type:'tertiary',
      href:"javascript:void(0)",
      className:"ot-sdk-show-settings",
      dataDesignCategory:'top_nav_vd',
    },
    {
      label: 'Accessibility',
      url: 'https://www.msdaccessibility.com',
    }
  ]
};


export default headerCopy;
