import styled from 'styled-components';
import { Link } from 'gatsby';
import { white, dotsBlue, dotsLightBlue, dotsPeach, pink, linkBlue, limeGreen,
altLeafGreen, dotsPurple } from '../../../styles/shared/colors';

function getShadow(shadow) {
  switch (shadow) {
    case 'pink':
      return pink;
    case 'green':
      return dotsLightBlue;
    case 'peach':
      return dotsPeach;
    case 'purple':
      return dotsPurple;
    case 'lime':
      return limeGreen;
    case false:
    default:
      return 'transparent';
  }
};

function getColor(type) {
  switch (type) {
    case 'primary-inverse':
      return dotsBlue;
    case 'secondary':
      return dotsBlue;
    case 'tertiary':
      return linkBlue;
    case 'primary':
    default:
      return white;
  }
}

function getBackgroundColor(type) {
  switch (type) {
    case 'primary-inverse':
      return white;
    case 'secondary':
      return 'transparent';
    case 'tertiary':
      return 'transparent';
    case 'primary':
    default:
      return dotsBlue;
  }
}

function getBorder(type, border) {
  if (border === 'white'){
    return white;
  } else if(type === 'tertiary'){
    return linkBlue;
  }
  return dotsBlue;
}

export const Button = styled(Link)`
  text-align: center;
  font-weight: 700;
  line-height: 22px;
  cursor: pointer;
  color: ${({type}) => getColor(type)};
  ${({border, type}) => border === 'none' ? `border: 0` : `border: 2px solid ${getBorder(type, border)}`};
  background-color: ${({type}) => getBackgroundColor(type)};
  position: relative;
  display: inline-block;
  padding: 12px;
  transition: all 0.3s ease-in;
  text-decoration: ${({underline}) => underline ? 'underline' : 'none'};

  img:not(.copy){
    height: 10px;
    width: 6px;
  }

  ${({shadow}) => shadow !== 'none' && `
    box-shadow: 3px 4px 0px ${getShadow(shadow)};`}

    .arrow-right, .copy, .arrow-left-inverse, .arrow-left {
      display: inline-block;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 16px; //10%
      filter: ${({type}) =>  type === 'primary-inverse' ? 'none' : 'brightness(0) invert(1)'};
    }

    .copy {
      filter: none;
    }

    .hide {
      display: none;
    }
    
    .arrow-left-inverse {
      left: 0;
    }

    .arrow-left {
      transform: rotate(180deg) translateY(50%);
      left: 16px; //10%
      filter: none;
    }

    &:hover {
      text-decoration: ${({type, border}) =>  (type === 'tertiary' && border === 'none') ? 'underline' : 'none'}; //Footer links
      ${({type, icon}) => type === 'primary' &&  icon !== 'none' &&`
      color:  ${getBackgroundColor(type)};
      background-color: ${getColor(type)};
      .arrow-right, .copy, .arrow-left-inverse, .arrow-left {
        filter: none;
      }
      `}
    }

    &.textCopied {
      background-color: ${altLeafGreen};
      border-color: ${altLeafGreen};
      &:hover {
        color: ${white};
      }
    }
`;