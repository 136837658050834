import styled from 'styled-components';
import { Link } from 'gatsby';
import { WrapperEl } from '../../../styles/shared/layout';
import { dotsBlue, dotsLightBlue, dotsPurple, menuBorder, pink, white, limeGreen} from '../../../styles/shared/colors';
import iconMenu from '../../../assets/icons/icon-menu.svg';
import iconClose from '../../../assets/icons/icon-menu-close.svg';
import Button from '../Button';
import { respondTo } from '../../../styles/shared/breakpoints';
import { mobileMenu, mobileNavOverlay } from '../../../styles/shared/zindex';

export const WeliregHeader = styled.header`
  background-color: ${dotsBlue};
  padding: 20px 0;
`;

export const HeaderWrapper = styled(WrapperEl)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1070px;
  padding-bottom: 10px;
`;

export const LogoLink = styled(Link)`
  img {
    display: block;
    height: 47px;
    width: auto;
    transition: 0.3s 0.3s opacity;
  }
  
  ${respondTo.sm`
    height: 60px;

    img {
      height: 60px;
    }
  `}
`;

export const NavItems = styled.nav`
  display: none;

  ${respondTo.sm`
    display: flex;
    flex: 1 0 0;
    justify-content: space-between;
  `}
`;

export const NavItem = styled(Button)`
  transition: border-color 0.3s;
  font-weight: 800;
  line-height: 18px;
  transition: border 0.3s ease-in;
  padding: 0 0 10px 0;
  position: relative;

  @media (min-width: 768px) and (max-width: 900px){
    flex: 1 0 0;
    line-height: 20px;
    margin: 0px 10px 0 10px;
  }

  &:last-child {
    margin: 0;
  }

  &:not(.mobile-menu) {
    border-bottom: 3px solid ${dotsBlue};
  }

  &.mobile-menu {
    display: block;
    width: 100%;
    padding: 25px 33px;
    text-align: left;
    border-top: 1px solid ${menuBorder};
    font-size: 19px;
    font-weight: 800;
    border-left: 7px solid ${dotsBlue};
    border-right: 7px solid ${dotsBlue};

    &:last-child {
      border-bottom: 1px solid ${menuBorder};
    }
  }
  &:hover {
    background-color: transparent;
  }
  &:hover,
  &.current {
    &:after {
      content: '';
      width: 100%;
      position: absolute;
      height: 3px;
      left: 0;
      bottom: 0;
    }
    &:nth-child(1):not(.mobile-menu):after {
      background-color: ${pink};
    }
    &:nth-child(2):not(.mobile-menu):after {
      background-color: ${limeGreen};
    }
    &:nth-child(3):not(.mobile-menu):after {
      background-color: ${dotsLightBlue};
    }
    &:nth-child(4):not(.mobile-menu):after {
      background-color: ${dotsPurple};
    }
    &.mobile-menu {
    &:nth-child(1) {
      color: ${pink};
      border-left: 7px solid ${pink};
      border-right: 7px solid ${pink};
      img {
        filter: invert(97%) sepia(41%) saturate(5134%) hue-rotate(286deg) brightness(105%) contrast(101%);
      }
    }
    &:nth-child(2) {
      color: ${limeGreen};
      border-left: 7px solid ${limeGreen};
      border-right: 7px solid ${limeGreen};
      img {
        filter: invert(100%) sepia(54%) saturate(977%) hue-rotate(30deg) brightness(102%) contrast(78%);
      }
    }
    &:nth-child(3) {
      color: ${dotsLightBlue};
      border-left: 7px solid ${dotsLightBlue};
      border-right: 7px solid ${dotsLightBlue};
      img {
        filter: invert(97%) sepia(57%) saturate(277%) hue-rotate(86deg) brightness(96%) contrast(93%);
      }
    }
    &:nth-child(4) {
      color: ${dotsPurple};
      border-left: 7px solid ${dotsPurple};
      border-right: 7px solid ${dotsPurple};
      img {
        filter: invert(72%) sepia(11%) saturate(1009%) hue-rotate(229deg) brightness(114%) contrast(77%);
      }
    }
  }
  }
`;

export const NavLinks = styled.div`
  flex: 1 0 0;
  display: flex;
  justify-content: space-evenly;
  align-items: flex-end;
`;

export const HcpButton = styled(Button)`
  font-weight: 800;
  font-size: 14px;
  padding: 12px 10px;
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 45px;
  width: 240px;

  &.mobile-menu {
    display: none;
   
  }

  &:hover {
    background: #ffffff;
    color: ${dotsBlue};
  }

`;

export const HamburgerButton = styled.button`
  position: relative;
  z-index: ${mobileMenu};
  display: inline-block;
  flex: 0 0 auto;
  padding: 40px 20px 6px;
  border: 0;
  border-radius: 0;
  background-color: ${dotsBlue};

  background-image: url(${iconMenu});
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;

  font-size: 12px;
  text-align: center;
  white-space: nowrap;
  line-height: 1;

 
  &.open {
    background-image: url(${iconClose});
    position: absolute;
    top: 22px;
    right: 20px;
  }

  ${ respondTo.sm`
    display: none;
  `}
`
export const MobileNavOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100vh;
  background-color: rgba(50,50,50, 0.7);
  display: ${({ isActive }) => (isActive ? 'block' : 'none')};
  z-index: ${mobileNavOverlay};

  ${respondTo.nav`
    display: none;
  `}
`;

export const NavTray = styled.div`
  position: absolute;
  top: 0;
  left: 100%;
  width: 0;
  overflow: auto;
  max-height: calc(100vh);
  background-color: ${dotsBlue};
  transition: all 0.3s ease-in-out; // TODO: Better curve
  z-index: ${mobileMenu};

  &.open {
    width: 100%;
    transform: translateX(-100%);
  }

  &.hidden {
    display: none;
  }

  ${ respondTo.sm`
    flex: 1 0 0;
    z-index: auto;
    left: 0;
    width: 0;
    max-height: none;
    padding: 0;
    box-shadow: none;
    transition: none;

    &.open {
      transform: 0;
    }
  `}
`;

export const PrimaryNav = styled.nav`
  border-width: 2px 0;
  ${ respondTo.sm`
    display: flex;
    height: 100%;
    align-items: stretch;
    padding: 0;
    border: 0;
  `}
`;

export const MobileHeaderWrap = styled.nav`
  background-color: ${dotsBlue};
  border-bottom: 1px solid rgba(255,255,255,0.2);
  position: sticky;
  top: 0;
  height: 80px;
  z-index: 9;
`;

export const MobileLogoLink = styled(Link)`
  position: relative;
  display: inline-block;
  padding: 20px 21px 12px 21px;

  img {
    display: block;
    height: 46px;
    width: auto;
  }

  ${ respondTo.sm`
    display: none;
  `}
`;

export const MobileDocQuestions = styled.section`
  border: 2px solid ${white};
  margin: 23px 14px;
  padding: 23px 24px 33px 24px;
  color: ${white};
  position: relative;
  box-shadow: 7px 8px 0px ${dotsPurple};

  a {
    width: 100%;
    display: block;

    img {
      height: 9px;
    }
  }

  .circle-1, .circle-2, .circle-3, .circle-4 {
    width: 12px;
    height: 12px;
    border: 3px solid ${white};
    position: absolute;
    right: 0;
    top: 0;
    border-radius: 50%;
    transform: translate(50%,-50%);
    background: ${dotsBlue};
    display: none;
  }

  .circle-2, .circle-4 {
    left: 0;
    transform: translate(-50%,-50%);
  }

  .circle-3, .circle-4 {
    bottom: 0;
    top: 100%;
  }

`;

export const MobileDocQuestionTitle = styled.p`
  font-size: 27px;
  line-height: 38px;
  font-family: 'noto-serif';
  margin-bottom: 8px;
`;

export const MobileDocQuestionCopy = styled.p`
  font-size: 17px;
  line-height: 26px;
  margin-bottom: 24px;
`;

export const MobileNavLinks = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 0 80px 0;
`;

export const MobileNavLink = styled(Button)`
    border: none;
    color: ${white};
    font-weight: 600;
    font-size: 14px;
    padding: 0;
    width: fit-content;
    font-weight: 600;
    font-size: 14px;
    padding: 0;
    margin-top: 18px;
`

export const DocQuestionsButton = styled(Button)`
  line-height: 20px;
  padding: 12px 20px;
  img {
    top: calc(50% + 1px) !important;
  }
`;

;
