import talkToDoctorMobImg from '../assets/images/illustration.svg';
import talkToDoctorDsktpImg from '../assets/images/doctor-graphic.svg';

const talkToDoctorShortCopy = {
    title: 'Talk with your doctor today',
    mobileImgSrc: talkToDoctorMobImg,
    desktopImgSrc: talkToDoctorDsktpImg,
    imgAlt: '',
    copy: 'If you think you or a family member may have VHL disease, talk with your doctor about how to get screened and if a genetic test could be right for you.',
    buttonLabel: 'Questions for your doctor',
    buttonTo: '/#talk-to-doctor-about-VHL',
};


export default talkToDoctorShortCopy;